.App {
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: space-between;
  margin: auto;
  max-width: 400px;
  min-height: 100vh;
  text-align: center;
}

.App-link {
  color: #61dafb;
}
