.Help {
  border-bottom: 1px solid #355786;
  font-size: 14px;
  padding: 1rem 0;
}

.Help-headline {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: space-between;
}

.Help-dummy {
  width: 30px;
}

.Help-close {
  background: none;
  border: 0;
  color: #355786;
  font-size: 20px;
  font-weight: bold;
  width: 30px;
}

.Help-subheadline {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 2rem;
}

.Help-list {
  display: grid;
  grid-template-columns: 2fr 3fr;
  padding: 1rem 3rem;
  text-align: left;
}
