.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Header h1 {
  font-size: 30px;
  margin: 15px 0;
  padding: 0;
}

.Header-headline span:first-child {
  padding-right: 1.5rem;
}

.Header-headline span:last-child {
  padding-left: 1.5rem;
}
