.Hint {
  border-top: 1px solid #355786;
  font-size: 14px;
  height: 300px;
  margin-top: 1rem;
  padding-top: 1rem;
}

.Hint-headline {
  column-gap: 10px;
  display: flex;
  justify-content: space-between;
}

.Hint-space {
  width: 30px;
}

.Hint-close {
  background: none;
  border: 0;
  color: #355786;
  font-size: 20px;
  font-weight: bold;
  width: 30px;
}

.Hint-form {
  display: flex;
  justify-content: center;
}

.Hint-form-element {
  font-size: 18px;
  max-height: 28px;
}

.Hint-autosuggest {
  display: inline-block;
  width: 260px;
}

.Hint-autosuggest * ul {
  padding: 0;
}

.Hint-autosuggest * li {
  list-style-type: none;
}

.Hint-suggestions {
  background-color: #20232A;
  border-radius: 4px;
  bottom: 75px;
  font-size: 18px;
  max-height: 200px;
  opacity: 0.85;
  overflow: auto;
  width: 260px;
}

.Hint-suggestions.suggested {
  bottom: 104px;
}

.Hint-suggestion {
  padding: 3px 0;
}
